<template>
  <label
    class="w-[44px] relative inline-block h-24"
  >
    <input
      v-model="checked"
      type="checkbox"
      class="slidingCheckbox hidden"
    >
    <span
      class="slidingCheckboxSlider rounded-full absolute cursor-pointer top-0 left-0 right-0 bottom-0 bg-grey400 transition-all duration-300 ease-in-out"
    />
  </label>
</template>

<script setup lang="ts">
import { watch } from 'vue';

const props = defineProps<{ checked: boolean }>();

const emit = defineEmits<{
  (e: 'selected', id: boolean): void,
}>();

const checked = ref(props.checked);
watch(checked, ()=> {
  emit('selected', checked.value);
});
watch(() => props.checked, () => {
  checked.value = props.checked;
});
</script>
